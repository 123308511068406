<template>
  <a-spin :spinning="loading">
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions"
        >
          <a-descriptions-item label="运营商">
            {{ !(typeof(subCards[0]) === 'undefined') ? subCards[0].carrier_type : "" }}
          </a-descriptions-item>
          <a-descriptions-item label="ICCID">
            {{ !(typeof(subCards[0]) === 'undefined') ? subCards[0].iccid : "" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions iotplt-descriptions-max-7"
        >
          <a-descriptions-item label="运营商">
            {{ !(typeof(subCards[1]) === 'undefined') ? subCards[1].carrier_type : "" }}
          </a-descriptions-item>
          <a-descriptions-item label="ICCID">
            {{ !(typeof(subCards[1]) === 'undefined') ? subCards[1].iccid : "" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions iotplt-descriptions-max-8"
        >
          <a-descriptions-item label="运营商">
            {{ !(typeof(subCards[2]) === 'undefined') ? subCards[2].carrier_type : "" }}
          </a-descriptions-item>
          <a-descriptions-item label="ICCID">
            {{ !(typeof(subCards[2]) === 'undefined') ? subCards[2].iccid : "" }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { findSubCardInfo } from '@/api/sim_card'

export default {
  name: 'ShowSubCardInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      submitting: false,
      subCards: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSubCardInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.subCards = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
